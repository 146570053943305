<template>
  <dx-util-popup
    ref="ratesWithAmazonPopupRef"
    :drag-enabled="true"
    :width="withAmazonRates ? '100vw' : '60vw'"
    :height="'95vh'"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="ratesWithAmazonPopupShown"
    @hidden="ratesWithAmazonPopupHidden"
  >
    <div class="container-popup">
      <div class="container-fluid h-100 d-flex flex-column">
        <dx-util-scroll-view width="100%" height="100%">
          <div class="row h-100 pb-4">
            <div v-if="showRates" class="pr-4" :class="withAmazonRates ? 'col-6': 'col-12'">
              <div class="card card-psh border m-half h-100">
                <div class="bg-light-primary rounded-top p-1">
                  <div class="psh-header mb-0 d-flex align-items-center">
                    <div class="mr-1 border-0">
                      <p-icon name="bi-truck" size="36px" color="primary" />
                    </div>
                    <div class="d-flex flex-column">
                      <h4 class="card-title mb-25">
                        PrepShipHub Rates
                      </h4>
                      <p class="card-text mb-0">
                        Shipping rate will be applied to customer <b class="text-warning">PrepShipHub</b> account
                      </p>
                    </div>
                    <div class="flex-grow-1" />
                    <img height="72" :src="require('@/assets/images/logo/logo.svg')" alt="">
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div v-if="!showAllRates" class="col-12">
                      <div v-for="item in pshMinRates" :key="item.id" class="col-12">
                        <div class="row h-100 mb-1 rate-item p-1" :class="selected.rateId === item.rateId ? 'bg-primary rounded' : ''" @click="selectRate(item)">
                          <div class="col-2 text-center">
                            <img v-if="item.carrier === 'USPS'" height="48" :src="require('@/assets/images/svg/carrier/usps.svg')" alt="">
                            <img v-if="item.carrier === 'UPS'" height="32" :src="require('@/assets/images/svg/carrier/ups.svg')" alt="">
                            <img v-if="item.carrier === 'FedEx'" height="32" :src="require('@/assets/images/svg/carrier/fedex.svg')" alt="">
                            <img v-if="item.carrier === 'DHL'" height="32" :src="require('@/assets/images/svg/carrier/dhl.svg')" alt="">
                          </div>
                          <div class="col-8">
                            <h4>
                              {{ item.serviceName }}
                            </h4>
                            <span>
                              Estimated delivery in {{ item.days }} days.
                            </span>
                          </div>
                          <div class="col-2 text-center text-warning">
                            <h4>
                              ${{ item.amount }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        These are the lowest option by each carrier. To see the full list please click<span role="button" class="text-info" @click="showAllRates = true">"Full List"</span>
                      </div>
                    </div>
                    <div v-else class="col-12">
                      <div v-for="item in pshRates" :key="item.id" class="col-12">
                        <div class="row h-100 mb-1 rate-item p-1" :class="selected.rateId === item.rateId ? 'bg-primary rounded' : ''" @click="selectRateFromAllPsh(item)">
                          <div class="col-2 text-center">
                            <img v-if="item.carrier === 'USPS'" height="48" :src="require('@/assets/images/svg/carrier/usps.svg')" alt="">
                            <img v-if="item.carrier === 'UPS'" height="32" :src="require('@/assets/images/svg/carrier/ups.svg')" alt="">
                            <img v-if="item.carrier === 'FedEx'" height="32" :src="require('@/assets/images/svg/carrier/fedex.svg')" alt="">
                            <img v-if="item.carrier === 'DHL'" height="32" :src="require('@/assets/images/svg/carrier/dhl.svg')" alt="">
                          </div>
                          <div class="col-8">
                            <h4>
                              {{ item.serviceName }}
                            </h4>
                            <span>
                              Estimated delivery in {{ item.days }} days.
                            </span>
                          </div>
                          <div class="col-2 text-center text-warning">
                            <h4>
                              ${{ item.amount }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="withAmazonRates" class="pl-4 col-6">
              <div class="card card-psh border m-half h-100">
                <div class="bg-light-primary rounded-top p-1">
                  <div class="psh-header mb-0 d-flex align-items-center">
                    <div class="mr-1 border-0">
                      <p-icon name="bi-truck" size="36px" color="primary" />
                    </div>
                    <div class="d-flex flex-column">
                      <h4 class="card-title mb-25">
                        Amazon Rates
                      </h4>
                      <p class="card-text mb-0">
                        Shipping rate will be applied to customer <b class="text-warning">Amazon</b> account
                      </p>
                    </div>
                    <div class="flex-grow-1" />
                    <img height="72" :src="require('@/assets/images/svg/ecommerce/amazonsp.svg')" alt="">
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div v-if="!showAllRates" class="col-12 py-1">
                      <div v-for="item in amzMinRates" :key="item.id" class="col-12">
                        <div class="row h-100 mb-1 rate-item p-1" :class="selected.rateId === item.rateId ? 'bg-primary rounded' : ''" @click="selectAmazonRate(item)">
                          <div class="col-2 text-center">
                            <img v-if="item.carrierId === 'USPS'" height="48" :src="require('@/assets/images/svg/carrier/usps.svg')" alt="">
                            <img v-if="item.carrierId === 'UPS'" height="32" :src="require('@/assets/images/svg/carrier/ups.svg')" alt="">
                            <img v-if="item.carrierId === 'AMZN_US'" height="32" :src="require('@/assets/images/svg/ecommerce/amazon.svg')" alt="">
                          </div>
                          <div class="col-8">
                            <h4>
                              {{ item.serviceName }}
                            </h4>
                            <span>
                              Delivery until {{ item.promise.deliveryWindow.end.monthValue }}/{{ item.promise.deliveryWindow.end.dayOfMonth }}/{{ item.promise.deliveryWindow.end.year }}.
                            </span>
                          </div>
                          <div class="col-2 text-warning">
                            <h4>
                              ${{ item.totalCharge.value }}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div class="">
                        These are the lowest option by each carrier. To see the full list please click <span role="button" class="text-info" @click="showAllRates = true">"Full List"</span>
                      </div>
                      <div>
                        <dx-util-select-box
                          v-model="selectedValAddServiceGroup"
                          label="Requested Confirmation"
                          class="mt-2"
                          :data-source="valAddServiceGroups"
                          display-expr="text"
                          value-expr="value"
                          @value-changed="updateSelection"
                        />
                      </div>
                    </div>
                    <div v-else class="col-12">
                      <div v-for="i in amazonRates" :key="i.rateId" class="col-12">
                        <div class="row h-100 mb-1 rate-item p-1" :class="selected.rateId === i.rateId ? 'bg-primary rounded' : ''" @click="selectRateFromAllAmz(i)">
                          <div class="col-2 text-center">
                            <img v-if="i.carrierId === 'USPS'" height="48" :src="require('@/assets/images/svg/carrier/usps.svg')" alt="">
                            <img v-if="i.carrierId === 'UPS'" height="32" :src="require('@/assets/images/svg/carrier/ups.svg')" alt="">
                            <img v-if="i.carrierId === 'AMZN_US'" height="32" :src="require('@/assets/images/svg/ecommerce/amazon.svg')" alt="">
                          </div>
                          <div class="col-8">
                            <h4>
                              {{ i.serviceName }}
                            </h4>
                            <span>
                              Delivery until {{ i.promise.deliveryWindow.end.monthValue }}/{{ i.promise.deliveryWindow.end.dayOfMonth }}/{{ i.promise.deliveryWindow.end.year }}.
                            </span>
                          </div>
                          <div class="col-2 text-warning">
                            <h4>
                              ${{ i.totalCharge.value }}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dx-util-scroll-view>
        <div class="text-right mt-1 px-2 fixed-bottom mt-2 pb-2">
          <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
          <dx-util-button text="Buy" type="success" style="width:100px" @click="handleSubmit" />
        </div>
      </div>
    </div>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import fbmService from '@/http/requests/fbm/fbmService'
import rateTypesEnum from '@/enums/rateType.enum.js'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    rateObject: {
      type: Object,
      default: () => {},
    },
    rateInformation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      withAmazonRates: false,
      selected: {
        isAmazonLabel: false,
      },
      valAddServiceGroups: [],
      selectedValAddServiceGroup: null,
      pshRates: {},
      amazonRates: {},
      showRates: false,
      pshMinRates: {},
      amzMinRates: {},
      showAllRates: false,
    }
  },
  computed: {
    ratesWithAmazonPopup() {
      return this.$refs.ratesWithAmazonPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.withAmazonRates = this.rateObject.hasAmazonRates
        this.ratesWithAmazonPopup.show()
        this.amazonRates = { ...this.rateObject.amazonRates }
        this.pshRates = { ...this.rateObject.pshRates }
        this.findLeastAmountByProvider(this.pshRates)
        this.findLeastAmountByAmzProvider(this.amazonRates)
      },
    },
  },
  created() {
  },
  methods: {
    ratesWithAmazonPopupShown() {
      this.popupTitle = this.withAmazonRates ? 'PrepShipHub & Amazon Shipping Rates' : 'PrepShipHub Shipping Rates'
      this.showRates = true
    },
    ratesWithAmazonPopupHidden() {
    // Clear form when popup is closed
      this.$emit('close')
    },
    closePopup() {
      this.ratesWithAmazonPopup.hide()
      this.showAllRates = false
      this.$emit('close')
    },
    handleSubmit() {
      if (!this.selected.rateId) {
        Notify.warning('You need to select a rate by clicking on it.')
        return
      }
      fbmService.purchaseLabel(this.selected).then(async () => {
        Notify.success('Shipping label purchased successfully')
        const result = await fbmService.getShippingLabels(this.rateInformation.referenceId)
        if (this.selected.rateType === rateTypesEnum.AMAZON_FBM.value) {
          const labelIds = result.map(el => el.id)
          labelIds.forEach(id => {
            if (this.selected.rateType === rateTypesEnum.AMAZON_FBM.value) {
              this.onDownloadFile(this.rateInformation.referenceId, id)
            }
          })
        } else {
          const labelUrls = result.map(el => el.labelUrl)
          labelUrls.forEach(url => {
            window.open(url, '_blank')
          })
        }
        this.$emit('close')
      }).finally(() => {
        this.selected = {
          isAmazonLabel: false,
        }
        this.closePopup()
      })
    },
    selectRate(item) {
      this.selected = { ...this.rateInformation, rateId: item.rateId, rateType: rateTypesEnum.PSH_GO_SHIPPO.value }
    },
    updateSelection(e) {
      this.selected = { ...this.selected, requestedConfirmation: e.value }
    },
    onDownloadFile(fbmOrderId, labelId) {
      return new Promise((resolve, reject) => {
        fbmService.getAmazonShippingLabelDocs(fbmOrderId, labelId)
          .then(response => {
            const contentType = response.headers['content-type']
            const contentDisposition = response.headers['content-disposition']
            const fileParts = contentDisposition.split('filename=')[1].replaceAll('"', '').split('.')
            const fileExtension = fileParts[1]
            const fileBlob = new Blob([response.data], { type: contentType })
            const anchor = document.createElement('a')
            anchor.href = window.URL.createObjectURL(fileBlob)
            anchor.download = `${fbmOrderId}-${labelId}.${fileExtension}`
            anchor.click()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    selectRateFromAllPsh(item) {
      this.showAllRates = false
      this.pshMinRates[item.carrier] = item
      this.selectRate(item)
    },
    findLeastAmountByProvider(shippingOptions) {
      const groupedOptions = Object.values(shippingOptions).reduce((acc, option) => {
        acc[option.carrier] = [...(acc[option.carrier] || []), option]
        return acc
      }, {})

      const lowestOptions = Object.entries(groupedOptions).reduce((acc, [carrier, options]) => {
        const minAmount = Math.min(...options.map(option => parseFloat(option.amount)))
        const lowestOption = options.find(option => parseFloat(option.amount) === minAmount)
        acc[carrier] = lowestOption
        return acc
      }, {})

      this.pshMinRates = lowestOptions
    },
    findLeastAmountByAmzProvider(shippingOptions) {
      const groupedOptions = Object.values(shippingOptions).reduce((acc, option) => {
        acc[option.carrierId] = [...(acc[option.carrierId] || []), option]
        return acc
      }, {})

      const lowestOptions = Object.entries(groupedOptions).reduce((acc, [carrierId, options]) => {
        const minAmount = Math.min(...options.map(option => parseFloat(option.totalCharge.value)))
        const lowestOption = options.find(option => parseFloat(option.totalCharge.value) === minAmount)
        acc[carrierId] = lowestOption
        return acc
      }, {})

      this.amzMinRates = lowestOptions
    },
    selectRateFromAllAmz(item) {
      this.showAllRates = false
      this.amzMinRates[item.carrierId] = item
      this.selectAmazonRate(item)
    },
    selectAmazonRate(item) {
      const services = item.availableValueAddedServiceGroups[0]?.valueAddedServices
      this.valAddServiceGroups = []
      services.forEach(el => {
        this.valAddServiceGroups.push({
          text: `${el.name} ($ ${el.cost.value})`, value: el.id, cost: el.cost.value,
        })
      })

      this.valAddServiceGroups.sort((a, b) => {
        if (a.cost < b.cost) return -1
        if (a.cost > b.cost) return 1

        if (a.text === 'DELIVERY_CONFIRMATION') return -1
        if (b.text === 'DELIVERY_CONFIRMATION') return 1

        return 0
      })

      const selectedDocumentOptions = this.selectPrintOption(item.supportedDocumentSpecifications)
      this.selectedValAddServiceGroup = this.valAddServiceGroups[0].value
      this.selected = {
        ...this.rateInformation,
        rateId: item.rateId,
        requestToken: this.rateObject.requestToken,
        rateType: rateTypesEnum.AMAZON_FBM.value,
        carrier: item.carrierId,
        serviceName: item.serviceName,
        requestedConfirmation: this.selectedValAddServiceGroup,
        requestedDocumentSpecification: {
          format: selectedDocumentOptions?.format,
          size: {
            width: selectedDocumentOptions?.size?.width,
            length: selectedDocumentOptions?.size?.length,
            unit: selectedDocumentOptions?.size?.unit,
          },
          pageLayout: selectedDocumentOptions?.printOptions[0]?.supportedPageLayouts[0],
          needFileJoining: selectedDocumentOptions?.printOptions[0]?.supportedFileJoiningOptions[0],
          requestedDocumentTypes: selectedDocumentOptions?.printOptions[0]?.supportedDocumentDetails.map(el => el.name),
        },
      }
    },
    selectPrintOption(documents) {
      let selectedDocument = null
      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.format === 'PDF' && doc.size.width === 4 && doc.size.length === 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.format === 'PNG' && doc.size.width === 4 && doc.size.length === 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const doc of documents) {
        if (doc.size.width >= 4 && doc.size.width <= 6 && doc.size.length >= 4 && doc.size.length <= 6) {
          selectedDocument = doc
          return selectedDocument
        }
      }

      if (documents.length > 0) {
        selectedDocument = documents[0]
      }

      return selectedDocument
    },
  },
}
</script>

<style lang="scss" scoped>
.container-popup {
  position: relative;
  height: 100%;
}

.rate-item:hover {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}
.rate-item:focus {
  background-color: rgb(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
