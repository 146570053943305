<template>
    <div>
      <div class="card mb-half">
        <div class="card-header pr-half pl-1 py-half bg-light-primary rounded">
          <div class="card-title">
            Box / Parcel Information
          </div>
          <div class="flex-grow-1"></div>
          <dx-util-button v-if="showBoxing" type="default" text="Boxing" class="ml-half" @click="openBoxing" />
          <dx-util-button icon="add" type="success" class="ml-half" @click="addBox" />
        </div>
        <div class="card-body m-0 mt-1 px-half" style="min-height:60px;">
          <div>
            <p class="text-warning">
              Insert the required number of boxes at the beginning before initiating any transaction.
            </p>
          </div>
          <dx-util-select-box
            v-model="shipFromId"
            :data-source="fromAddresses"
            display-expr="text"
            value-expr="id"
            item-template="item"
            label-mode="floating"
            label="Ship From"
            class="mb-half flex-grow-1"
            :show-clear-button="true"
            @selection-changed="onShipToAddressChange"
          >
            <template #item="{ data }">
              <div v-html="data.textHtml" />
            </template>
          </dx-util-select-box>
          <dx-util-validation-group :ref="packValidationRef">
            <div v-for="box in boxes" :key="box.id">
              <fbm-box :box-id="box.id">
                <dx-util-button icon="icon icon-feather-copy" type="success" class="ml-half mt-half" @click="duplicateBox(box.id)" />
                <dx-util-button icon="close" class="mt-half ml-half" type="danger" @click="removeBox(box.id)" />
              </fbm-box>
            </div>
          </dx-util-validation-group>
        </div>
      </div>
      <div class="d-flex pt-0">
        <div class="w-50 pr-half">
          <dx-util-button class="d-block d-inline" type="danger" text="Cancel" @click="closeForm" />
        </div>
        <div class="w-50 pl-half">
          <dx-util-button class="d-block d-inline" type="success" text="Get Rates" @click="getRates" />
        </div>
      </div>
      <div>
        <fbm-rates-with-amazon
          :component-id="ratesWithAmazonComponentId"
          :rate-object="rateObject" :reference-key="'FBM'"
          :rate-information="rateInformation"
          @close="close"
        />
      </div>
      <item-selector-for-amazon-rates :component-id="itemSelectorCompId" :box="selectedBox" />
      <fbm-boxing :component-id="boxingCompId" />
  </div>
</template>

<script>
import commonService from '@/http/requests/common/commonEntityService'
import { Notify } from '@/@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import fbmService from '@/http/requests/fbm/fbmService'
// import amazonApi from '@/http/requests/external/amazon.fba'
import notify from '@/@robustshell/utils/notify'
import useFbmState from '../useFbmStates'
import FbmRatesWithAmazon from './FbmRatesWithAmazon.vue'

export default {
  components: {
    'fbm-rates-with-amazon': FbmRatesWithAmazon,
    'item-selector-for-amazon-rates': () => import('./ItemSelectorForBox.vue'),
    'fbm-box': () => import('./FbmBox.vue'),
    'fbm-boxing': () => import('./FbmBoxing.vue'),
  },
  props: {
    buyLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      packValidationRef: 'targetGroup1',
      selectedRate: {},
      shipFromId: '',
      fromPhone: '',
      // boxes: [],
      packingBoxes: [],
      rateList: [],
      rateObject: {},
      rateInformation: {},
      rateListComponentId: '',
      itemSelectorCompId: '',
      ratesWithAmazonComponentId: '',
      boxingCompId: '',
      defaultMetrics: {},
      pack: {
        count: 1,
        length: null,
        width: null,
        height: null,
        weightLb: 0,
        weightOz: 0,
        description: '',
        insuredValue: 0,
        isHazmat: false,
      },
      amazonRatesRequest: {},
      orderItemsList: [],
      selectedBox: {},
      fromAddresses: [],
    }
  },
  setup() {
    const {
      boxes,
      currentOrder, getShippingLabels, orderItems, selectedOrderItems, showCustomsDeclaration,
    } = useFbmState()
    return {
      boxes,
      currentOrder,
      getShippingLabels,
      orderItems,
      selectedOrderItems,
      showCustomsDeclaration,
    }
  },
  computed: {
    boxValidationGroup() {
      return this.$refs[this.packValidationRef].instance
    },
    needItemSelection() {
      return this.currentOrder.fbmResourceType === 'AMAZON' && this.orderItems.length > 1 && this.boxes.length > 1
    },
    orderSkuCount() {
      return this.orderItems.length
    },
    itemCount() {
      return this.orderItems.reduce((acc, cur) => acc + cur.orderQuantity, 0)
    },
    boxCount() {
      return this.boxes.length
    },
    showBoxing() {
      return this.orderSkuCount > 1 && this.itemCount > 1 && this.boxCount >= 1
    },
    preventMultipleSkusWithBoxes() {
      return this.orderSkuCount > 1 && this.itemCount > 1 && this.boxCount === 1
    },

  },
  watch: {
    buyLabel: {
      immediate: true,
      deep: true,
      async handler() {
        this.pack.id = uuidv4()
        if (this.currentOrder?.id) {
          const result = await fbmService.getBoxDimensions(this.currentOrder.id)
          this.defaultMetrics = result
        }
        this.boxes = []
        this.addBox()
        this.getCompanyAddresses(this.currentOrder?.companyId)
      },
    },
  },
  methods: {
    onShipToAddressChange(e) {
      const fromCountry = e.selectedItem.country?.slice(0, 2)
      if (fromCountry !== this.currentOrder.shippingAddress.country.code2) {
        this.showCustomsDeclaration = true
      } else {
        this.showCustomsDeclaration = false
      }
    },
    openBoxing() {
      this.boxingCompId = uuidv4()
    },
    closeForm() {
      this.$emit('emit-close')
    },
    close() {
      this.selectedRate = {}
      this.rateObject = {}
      this.getShippingLabels(this.currentOrder.id)
      this.$emit('emit-close')
    },
    selectRate() {
      if (!this.selectedRate?.id) {
        Notify.warning('Please select a rate')
        return
      }
      this.$emit('on-emit-item-selected', this.selectedRate)
      this.close()
    },
    addBox() {
      if (this.showBoxing) {
        notify.error('You should use boxing button for multiple skus and multiple boxes')
        return
      }
      // Add default metrics only for the first parcel information and if default
      // metrics exists. Length is 0, because it is the first one to be added
      if (this.defaultMetrics && this.boxes.length === 0) {
        this.boxes.push({
          id: uuidv4(),
          weightLb: this.defaultMetrics.weightLb,
          weightOz: this.defaultMetrics.weightOz,
          count: 1,
          length: this.defaultMetrics.length,
          width: this.defaultMetrics.width,
          height: this.defaultMetrics.height,
          description: '',
          shipBox: [...this.orderItems],
          insuredValue: 0,
          isHazmat: false,
        })
      } else {
        this.boxes.push({
          id: uuidv4(),
          weightLb: 0,
          weightOz: 0,
          count: 1,
          length: 0,
          width: 0,
          height: 0,
          description: '',
          shipBox: [...this.orderItems],
          insuredValue: 0,
          isHazmat: false,
        })
      }
    },
    removeBox(uid) {
      if (this.boxes.length === 1) return
      this.boxes = this.boxes.filter(el => el.id !== uid)
      // If one box left, put all items into it.
      if (this.boxes.length === 1) {
        this.boxes[0].shipBox = [...this.orderItems]
      }
    },
    duplicateBox(uid) {
      if (this.orderItems?.length === 1 && this.orderItems[0].orderQuantity === 1) {
        Notify.warning('The quantity of this single item is 1.')
        return
      }
      const selectedBox = this.boxes.filter(el => el.id === uid)[0]
      this.boxes.push({
        id: uuidv4(),
        weightLb: selectedBox.weightLb,
        weightOz: selectedBox.weightOz,
        count: selectedBox.count,
        length: selectedBox.length,
        width: selectedBox.width,
        height: selectedBox.height,
        description: selectedBox.description,
        shipBox: [...this.orderItems],
        insuredValue: 0,
        isHazmat: false,
      })
    },
    checkNullOrUndefined(value) {
      return value === null || value === undefined ? '' : value
    },
    getCompanyAddresses(companyId) {
      this.fromAddresses.length = 0
      commonService
        .fetchAddressesByTenantAndCompany(companyId)
        .then(result => {
          const { data } = result
          data.forEach(item => {
            let line = item.line1
            line += item.line2 ? ` ${item.line2}` : ''
            line += item.line3 ? ` ${item.line3}` : ''
            const addressStringHtml = `${item.name}<br />${line}<br />${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            const addressString = `${item.name} ${line}  ${item.city}, ${item.state} ${item.zipcode} ${item.country}`
            this.fromAddresses.push({
              id: item.id,
              textHtml: addressStringHtml,
              text: addressString,
              country: item.country,
            })
          })
        }).then(() => {
          this.shipFromId = this.fromAddresses[0]?.id
        })
    },
    async getRates() {
      // Validations start
      const boxValidation = await this.boxValidationGroup.validate()
      if (!boxValidation.isValid) {
        Notify.warning('Please fill out the missing information in the box / parcel information')
      }

      const hasEmptyShipBox = this.boxes.some(box => Array.isArray(box.shipBox) && box.shipBox.length === 0)
      if (hasEmptyShipBox) {
        Notify.warning('Please fill box with items. There is at least one empty box, add item or remove it')
        return
      }

      const allBoxesHaveWeight = this.boxes.every(box => {
        const totalWeight = box.weightOz + box.weightLb
        return totalWeight > 0
      })

      if (!allBoxesHaveWeight) {
        Notify.warning('Each box should have a weight greater than 0.')
        return
      }

      if (this.boxes?.length === 1 && this.currentOrder.fbmResourceType === 'AMAZON') {
        const allItemsWeightsInOz = []
        const boxWeightsInOz = (this.boxes[0].weightLb * 16) + this.boxes[0].weightOz
        this.boxes[0].shipBox.forEach(item => {
          allItemsWeightsInOz.push(item.weight * 16 * item.reservedQuantity)
        })
        const totalItemWeight = allItemsWeightsInOz.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        if (totalItemWeight > boxWeightsInOz) {
          Notify.warning('Box weight cannot be less than item weight. Total item weight in the boxes are heavier than the declared box weight!')
          return
        }
      }

      const boxPayload = []
      this.boxes.forEach(box => {
        let qty = 0
        const boxSingleQty = parseInt(box.singleItemUpdateQty, 10)
        if (boxSingleQty && boxSingleQty > 0) {
          qty = boxSingleQty
        }

        const sanitizedShipBox = box.shipBox.map(el => {
          let shipCount = 0
          if (el.updatedQuantity && el.updatedQuantity > 0) {
            shipCount = el.updatedQuantity
          } else if (qty > 0) {
            shipCount = qty
          } else {
            shipCount = el.orderQuantity
          }
          return {
            fbmOrderItemId: el.id,
            shipCount: shipCount,
            productName: el.productName,
          }
        })

        boxPayload.push({
          ...box,
          id: '',
          shipBox: { items: sanitizedShipBox },
          description: uuidv4(),
        })
      })

      // Fill toAddress
      const shippingAddress = this.currentOrder.shippingAddress

      const toAddress = {
        name: `${this.checkNullOrUndefined(shippingAddress.firstName)} ${this.checkNullOrUndefined(shippingAddress.lastName)}`,
        street1: this.checkNullOrUndefined(shippingAddress.address1),
        street2: this.checkNullOrUndefined(shippingAddress.address2),
        city: this.checkNullOrUndefined(shippingAddress.city),
        state: this.checkNullOrUndefined(shippingAddress.state?.code),
        zip: this.checkNullOrUndefined(shippingAddress.postcode),
        country: this.checkNullOrUndefined(shippingAddress.country?.code2),
        phone: this.checkNullOrUndefined(shippingAddress.phone),
      }

      this.rateInformation = {
        companyId: this.currentOrder.companyId,
        shipFromId: this.shipFromId,
        toAddress: toAddress,
        shipmentParcels: boxPayload,
        notes: this.notes,
        fromName: this.currentOrder.companyName,
        fromAddress: this.fromAddress,
        referenceKey: 'FBM',
        referenceId: this.currentOrder.id,
      }

      if (boxValidation.isValid) {
        fbmService.getShippingLabelRates(this.rateInformation).then(res => {
          // Prepare the PSH rates data for the viewer component
          const pshRates = res?.pshRates?.map(item => ({
            carrier: item.provider,
            currency: item.currency,
            amount: item.amount,
            days: item.estimated_days,
            imageUrl: item.provider_image_75,
            serviceName: item.servicelevel.name,
            rateId: item.objectId,
          }))

          this.rateObject.pshRates = pshRates

          // Amazon rates assigned to rate object
          this.rateObject.amazonRates = res.amazonRates
          this.rateObject.requestToken = res.requestToken
          this.rateObject.hasAmazonRates = res.hasAmazonRates

          return this.rateObject
        }).then(rates => {
          if (rates.pshRates?.length > 0 || rates.amazonRates?.length > 0) {
            this.ratesWithAmazonComponentId = uuidv4()
          } else {
            Notify.info('No carrier rates is available')
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  position: absolute;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(100%);
}
</style>
